import React from "react";

import ContentContainer from "../shared/ContentContainer";
import ContentTitle from "../shared/ContentTitle";
import ContentSubTitle from "../shared/ContentSubTitle";

const VerifyEmailContent = () => (
    <ContentContainer>
        <ContentTitle>
            Trial
        </ContentTitle>
        <ContentSubTitle>
            Thank you for getting started with Frends!
        </ContentSubTitle>
        <ContentSubTitle>
            Before being able to continue please verify your email using the link send to your email address.
        </ContentSubTitle>
    </ContentContainer>
);

export default VerifyEmailContent;